import cartService from "../../services/cart.service";
import { showPopup } from "../../utils/helpers";
import router from "../../router/index";

const state = {
  cart: {},
  cartSession: null,
  cartSessionId: localStorage.getItem("cartSessionId") || null,
  checkoutTimeOut: 900,
  timeLeft: 0,
  timerId: null,
};

const mutations = {
  setCartSession(state, session) {
    state.cartSession = session;
    localStorage.setItem("cartSessionId", session._id);
    state.cartSessionId = session._id;

    const startTime = new Date(session.createdAt).getTime();
    const endTime = startTime + state.checkoutTimeOut * 1000;
    const now = Date.now();

    state.timeLeft = Math.round((endTime - now) / 1000); // seconds

    if (state.timeLeft < 0) state.timeLeft = 0;
  },

  updateCartSession(state, session) {
    state.cartSession = session;
  },

  setTimeLeft(state, time) {
    state.timeLeft = time;
  },

  setTimerId(state, id) {
    state.timerId = id;
  },

  deleteCartSession(state) {
    localStorage.removeItem("cartSessionId");
    state.cartSessionId = null;
    state.cartSession = null;
  },
};

const actions = {
  async createCartSession({ commit, dispatch, state }, data) {
    try {
      // Delete old session first if found
      if (state.cartSessionId) {
        await dispatch("deleteCartSession");
      }

      const res = await cartService.createCartSession(data);
      commit("setCartSession", res.data.session);
      router.push("/checkout");
      dispatch("calcTime");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  updateCartSession({ commit }, data) {
    return cartService
      .updateCartSession(data)
      .then(({ data }) => {
        commit("updateCartSession", data.session);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  getCartSession({ commit, dispatch }, id) {
    return cartService
      .getCartSession(id)
      .then((res) => {
        commit("setCartSession", res.data.session);
        dispatch("calcTime");
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  deleteCartSession({ commit, state }) {
    return cartService
      .deleteCartSession(state.cartSessionId)
      .then(() => {
        commit("deleteCartSession");
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  calcTime({ commit, state, dispatch }) {
    if (state.timerId) clearInterval(state.timerId);

    const timer = setInterval(async () => {
      if (state.timeLeft > 0) {
        const timeLeft = state.timeLeft - 1;
        commit("setTimeLeft", timeLeft);
      } else {
        if (state.timerId) clearInterval(state.timerId);

        try {
          await dispatch("deleteCartSession");
        } catch (err) {
          showPopup(err, "error");
        }
      }
    }, 1000);

    commit("setTimerId", timer);
  },
};

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};
