import router from "../router/index";

export const validateRequest = (err) => {
  // Too many requests
  if (err.response?.status == 429) {
    return "Liikaa pyyntöjä. Yritä uudelleen 10 sekunnin kuluttua.";
  }

  // Not found or restricted 403
  if (err.response?.status == 403) {
    router.push("/404").catch(() => {});
    return "Virheellinen pyyntö.";
  }

  // Conflict with overlapping when trying to set to cart
  if (err.response?.status == 409) {
    setTimeout(() => {
      router.push("/").catch(() => {});
    }, 1000);
  }

  // Message
  const errorMessage = err.response?.data?.message;

  if (errorMessage) {
    if (errorMessage.includes("email_1 dup key")) return "Email on jo käytössä";

    return errorMessage;
  }

  return err;
};
