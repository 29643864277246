import stripeService from "../../services/stripe.service";

const state = {
  checkoutSession: null,
};

const mutations = {
  setCheckoutSession(state, session) {
    state.checkoutSession = { ...session };
  },
};

const actions = {
  getCheckoutSession({ commit }, data) {
    return stripeService
      .getCheckoutSession(data)
      .then(({ data }) => {
        commit("setCheckoutSession", data.session);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
