import { apiAgent } from "./apiAgent";

export default {
  async createCartSession(data) {
    return apiAgent.post("cart/create-cart-session/", data);
  },

  async updateCartSession(data) {
    return apiAgent.patch(`cart/update-cart-session/${data.sessionId}`, data);
  },

  async deleteCartSession(id) {
    return apiAgent.delete("cart/delete-cart-session/" + id);
  },

  async getCartSession(id) {
    return apiAgent.get("cart/get-cart-session/" + id);
  },
};
