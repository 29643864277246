import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";
import "./i18n";
import titleMixin from "./mixins/titleMixins";
import { apiAgent } from "./services/apiAgent";
import { validateRequest } from "././utils/validateErrors";
import "./directives"; // Custom directives

// Vuetify
import vuetify from "./plugins/vuetify";

// Title mixins
Vue.mixin(titleMixin);

// Global eventbus
export const eventBus = new Vue();

// Eventbus for snackbar toasts
export const snackbarBus = new Vue();

// STYLES
import "@/assets/scss/theme.scss";

// Interceptor for blob error response
apiAgent.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.request.responseType === "blob" &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf("json") != -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(error));
        };

        reader.onerror = () => {
          reject(error);
        };

        reader.readAsText(error.response.data);
      });
    } else {
      error = validateRequest(error);
    }

    return Promise.reject(error);
  }
);

// Attach token to headers on requests
apiAgent.interceptors.request.use(
  (config) => {
    let query = null;

    const path = window.location.pathname.split("/")[1];
    const slug = path.split("/")[0];

    const hrefArr = window.location.href.split("?");

    if (hrefArr.length > 1) {
      query = hrefArr[1];
    }

    if (slug) {
      config.headers["public_slug"] = slug;
    }

    if (query) {
      const keyValues = query.split("&");
      let finalQuery = {};

      keyValues.forEach((el) => {
        const arr = el.split("=");
        const key = arr[0];
        const val = arr[1];
        finalQuery[key] = val;
      });

      config.params = finalQuery;
    }

    return config;
  },

  (error) => {
    error = validateRequest(error);
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
