import { apiAgent } from "../services/apiAgent";

export default {
  async createReservation(data) {
    return apiAgent.post("reservation/create-reservation/", data);
  },

  async getReservation(id) {
    return apiAgent.get("reservation/get-reservation/" + id);
  },

  async updateReservationState(obj) {
    return apiAgent.patch("reservation/update-reservation-state/" + obj.id, { state: obj.state });
  },

  async setUrlBackToVendor(id) {
    return apiAgent.patch("reservation/set-url-to-vendor/" + id);
  },
};
