<template>
  <div
    :style="{
      background: settings.backgroundColor,
    }"
  >
    <v-app
      v-if="!loading"
      id="app"
      :style="{
        background: settings.backgroundColor,
      }"
    >
      <v-container class="main">
        <!-- Menu -->
        <toolbar-menu></toolbar-menu>
        <!-- Router view -->
        <router-view></router-view>
      </v-container>
    </v-app>

    <!-- Snackbars -->
    <v-snackbars :objects.sync="objects" :timeout="6000" top center>
      <template #action="{ close }">
        <v-btn text @click="close()">Sulje</v-btn>
      </template>
    </v-snackbars>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { snackbarBus } from "./main";
import mixins from "./mixins/mixins";
import VSnackbars from "v-snackbars";
import ToolbarMenu from "./components/ToolbarMenu.vue";
import { loadFont } from "./helpers/helpers";

export default {
  name: "App",

  mixins: [mixins],

  components: {
    VSnackbars,
    ToolbarMenu,
  },

  data: () => ({
    objects: [],
  }),

  computed: {
    ...mapState("account", ["settings", "loading"]),
    ...mapState("cart", ["cartSessionId", "cartSession"]),
  },

  watch: {
    settings(val) {
      // set default colors
      this.$store.state.account.colors.fieldColor = {
        "--fieldColor": val.fieldColor,
      };
      this.$store.state.account.colors.fieldTextColor = {
        "--fieldTextColor": val.fieldTextColor,
      };
      this.$store.state.account.colors.contactFieldColor = {
        "--contactFieldColor": val.contactFieldColor,
      };
      this.$store.state.account.colors.contactFieldTextColor = {
        "--contactFieldTextColor": val.contactFieldTextColor,
      };
      this.$store.state.account.colors.errorColor = {
        "--errorColor": val.errorColor,
      };
    },

    loading(val) {
      // load font to document
      if (!val) {
        this.$nextTick(() => {
          if (this.settings?.font) loadFont(this.settings?.font);
        });
      }
    },
  },

  async created() {
    snackbarBus.$on("createsnackbar", this.showSnackbar);

    try {
      // Get settings
      await this.getAccountData();
    } catch (err) {
      return this.showPopup("Jokin meni pieleen" + " " + err, "error");
    }

    // Get cart session
    if (this.cartSessionId && !this.cartSession) {
      try {
        await this.getCartSession(this.cartSessionId);
      } catch (err) {
        await this.deleteCartSession(this.cartSessionId);
      }
    }
    // Set loading false and show app
    this.$store.state.account.loading = false;
  },

  methods: {
    ...mapActions("account", ["getAccountData"]),
    ...mapActions("cart", ["getCartSession", "deleteCartSession"]),

    showSnackbar(value) {
      this.objects.push({
        message: value.text,
        color:
          value.color == "error"
            ? this.settings.errorColor
            : value.color == "success"
            ? this.settings.successColor
            : "black",
      });
    },
  },
};
</script>

<style scoped>
.main {
  max-width: 1680px;
}
</style>
