import contactService from "../../services/contact.service";
import _ from "lodash";

const state = {
  contactForm: { termsAgreeCheck: false, name: "" },
  emptyResultsForm: { termsAgreeCheck: false, name: "", allocation: [], area: [] },
  sendStatus: { message: "", error: false },
};

const mutations = {
  setStatus(state, data) {
    state.sendStatus.message = data.message;
    state.sendStatus.error = data.error;
  },

  setFormField(state, obj) {
    state[obj.form] = _.set(state.emptyResultsForm, obj.field, obj.val);
  },
};

const actions = {
  submitContactForm({ commit }, data) {
    return contactService
      .submitContactForm(data)
      .then(() => {
        commit("setStatus", {
          message: "Kiitos yhteydenotosta! Viestin lähetys onnistui.",
          error: false,
        });
      })
      .catch(() => {
        commit("setStatus", {
          message: "Tapahtui virhe! Yritä myöhemmin uudelleen tai ota yhteyttä puhelimitse.",
          error: true,
        });
      });
  },

  async submitEmptyResultForm(c, data) {
    await contactService.submitEmptyResultForm(data);
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
