import Vue from "vue";
import VueRouter from "vue-router";
import mixins from "../mixins/mixins";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "apartments" */ "../views/Apartments.vue"),
  },
  {
    path: "/kohteet/:id",
    name: "single",
    component: () =>
      import(/* webpackChunkName: "singleapartment" */ "../views/SingleApartment.vue"),
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () => import(/* webpackChunkName: "checkout" */ "../views/Checkout.vue"),
  },
  {
    path: "/reservation-completed",
    name: "completed-checkout",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/Stripe/ReservationCompleted.vue"),
  },
  {
    path: "/reservation-failed",
    name: "failed-checkout",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/Stripe/ReservationFailed.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
  {
    path: "*",
    redirect: { name: "404" },
  },
];

const path = window.location.pathname.split("/")[1];
const slug = path.split("/")[0];

const router = new VueRouter({
  mode: "history",
  base: `/${slug}`,
  routes,
  scrollBehavior() {
    mixins.methods.scrollToTop();
  },
});

export default router;
