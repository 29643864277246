export const loadFont = (fontFamily = "Bai Jamjuree") => {
  const googleFontFormat = fontFamily.split(" ").join("+");

  const link = document.createElement("link");
  link.href = `https://fonts.googleapis.com/css2?family=${googleFontFormat}:wght@300;400;500;600;700&display=swap`;
  link.rel = "stylesheet";
  document.head.appendChild(link);

  // load font to vuetify
  document.body.style.setProperty("--custom-body-font", fontFamily);
};
