<template>
  <v-toolbar v-show="showToolbar" flat dense>
    <!-- Back -->
    <div v-if="showBackBtn" style="cursor: pointer" class="mb-1" @click="$router.go(-1)">
      <v-icon>mdi-arrow-left</v-icon> Palaa takaisin
    </div>

    <div v-if="showBackToVendor" style="cursor: pointer" class="mb-1" @click="goBackWebsite">
      <v-icon>mdi-arrow-left</v-icon> Palaa takaisin kauppiaan sivuille
    </div>
    <v-spacer></v-spacer>

    <!-- Cart -->
    <v-tooltip v-if="settings.type == 1 && showCartIcon" bottom color="#2d2d2d">
      <template v-slot:activator="{ on, attrs }">
        <v-icon :color="settings.primaryColor" v-bind="attrs" v-on="on" @click="goToCheckout"
          >mdi-cart</v-icon
        >
      </template>
      <div v-if="cartSession">
        <div>Ostoskori | {{ formatTimer(timeLeft) }}</div>
        <div>{{ cartSession.apartment.address }}, {{ cartSession.apartment.area }} m²</div>
      </div>
      <div v-else>
        <div>Ostoskori on tyhjä.</div>
        <div>Lisää vuokrakohde!</div>
      </div>
    </v-tooltip>

    <!-- Language selector -->
    <!-- <div class="language">
      <v-select
        v-model="language"
        outlined
        dense
        :items="languages"
        hide-details
        item-color=""
        :color="settings.primaryColor"
      >
        <template v-slot:selection="{ item }">
          <img :src="`/images/${item}.svg`" />
        </template>
        <template v-slot:item="{ item }">
          <img :src="`/images/${item}.svg`" />
        </template>
      </v-select>
    </div> -->
  </v-toolbar>
</template>

<script>
import { mapState } from "vuex";
import mixins from "../mixins/mixins";

export default {
  mixins: [mixins],

  data() {
    return {
      languages: ["en", "fi"],
      backBtnPages: ["single", "checkout"],
      cartPages: ["home", "single"],
      toolbarPages: ["single"],
    };
  },

  computed: {
    ...mapState("account", ["settings"]),
    ...mapState("cart", ["cartSession", "timeLeft"]),
    ...mapState("reservation", ["currentReservation"]),

    showToolbar() {
      return (
        (this.toolbarPages.includes(this.$route.name) && this.settings.type == 0) ||
        this.settings.type == 1
      );
    },

    showBackBtn() {
      return this.backBtnPages.includes(this.$route.name);
    },

    showCartIcon() {
      return this.cartPages.includes(this.$route.name);
    },

    showBackToVendor() {
      return this.$route.name === "completed-checkout" || this.$route.name === "failed-checkout";
    },

    language: {
      get() {
        return localStorage.getItem("language");
      },
      set(val) {
        localStorage.setItem("language", val);
        this.$router.go();
      },
    },
  },

  methods: {
    goToCheckout() {
      if (this.cartSession) this.$router.push("/checkout");
    },

    goBackWebsite() {
      if (!this.currentReservation || this.currentReservation.returnToVendorUrl) {
        location.href = this.settings.websiteUrl;
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style>
.language {
  width: 75px;
}

.v-toolbar__content {
  padding: 0px;
}
</style>
