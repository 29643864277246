import { apiAgent } from "../services/apiAgent";

export default {
  searchApartments(data) {
    return apiAgent.post(`apartment?page=${data.page}&itemsPerPage=${data.itemsPerPage}`, {
      search: data.search,
      query: data.query,
    });
  },

  async getOneApartment(id) {
    return await apiAgent.get(`apartment/${id}`);
  },
};
