import reservationService from "../../services/reservation.service";
// import { showPopup } from "../../utils/helpers";

const state = {
  reservationForm: {
    isCompany: false,
    companyName: "",
    businessId: "",
    name: "",
    address: "",
    zipCode: "",
    city: "",
    phone: "",
    email: "",
    email2: "",
    message: "",
  },
  sendStatus: { message: "", error: false },
  currentReservation: null,
};

const mutations = {
  setStatus(state, data) {
    state.sendStatus.message = data.message;
    state.sendStatus.error = data.error;
  },

  setCurrentReservation(state, reservation) {
    state.currentReservation = { ...reservation };
  },

  setFormValue(state, obj) {
    state.reservationForm[obj.field] = obj.val;
  },
};

const actions = {
  async createReservation({ commit }, data) {
    try {
      const res = await reservationService.createReservation(data);
      // Set session url to stripe module
      commit("stripe/setCheckoutSession", res.data.session, { root: true });
    } catch (err) {
      commit("setStatus", {
        message: "Tapahtui virhe! Yritä myöhemmin uudelleen tai ota yhteyttä puhelimitse.",
        error: true,
      });
    }
  },

  getReservation({ commit }, id) {
    return reservationService
      .getReservation(id)
      .then(({ data }) => {
        if (data.reservation) commit("setCurrentReservation", data.reservation);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  updateReservationState({ commit }, obj) {
    return reservationService
      .updateReservationState(obj)
      .then(({ data }) => {
        if (data.reservation) commit("setCurrentReservation", data.reservation);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  setUrlBackToVendor(ctx, id) {
    return reservationService
      .setUrlBackToVendor(id)
      .then(() => {
        return;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
