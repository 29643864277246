import Vue from "vue";
import Vuex from "vuex";
import apartment from "./modules/apartment";
import account from "./modules/account";
import contact from "./modules/contact";
import reservation from "./modules/reservation";
import stripe from "./modules/stripe";
import cart from "././modules/cart";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    API_BASE_URL: "",
  },
  mutations: {},
  actions: {},
  modules: { apartment, account, contact, reservation, stripe, cart },
});
