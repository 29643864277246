import { snackbarBus } from "../main";
import moment from "moment";

export default {
  methods: {
    showPopup: (text, color) => {
      snackbarBus.$emit("createsnackbar", {
        text: text,
        color: color,
      });
    },

    scrollToTop() {
      const doc = document.getElementById("app");
      if (doc) doc.scrollIntoView({ behavior: "smooth" });
    },

    scrollToBottom() {
      const doc = document.getElementById("app");
      if (doc) doc.scrollIntoView({ behavior: "smooth", block: "end" });
    },

    // FORMATTING

    formatTimer() {
      let minutes = Math.floor(this.timeLeft / 60);
      let seconds = this.timeLeft % 60;

      if (seconds < 10) seconds = `0${seconds}`;
      if (minutes < 10) minutes = `0${minutes}`;
      if (minutes == 0) minutes = "00";

      return `${minutes}:${seconds}`;
    },

    imagesFound(apartment) {
      return apartment.images.length > 0 || apartment.condominium.images.length > 0 ? true : false;
    },

    setThumbnailImage(apartment) {
      // Set condominium image first, if not found, set apartment image
      if (apartment.condominium.images.length > 0) {
        return this.getImageCloudFrontUrl(apartment.condominium.images[0]);
      } else if (apartment.images.length > 0) {
        return this.getImageCloudFrontUrl(apartment.images[0]);
      } else {
        return "";
      }
    },

    getImageCloudFrontUrl(img) {
      const timestamp = new Date().getTime();
      return `https://${process.env.VUE_APP_CLOUDFRONT_URL}/${img.key}?t=${timestamp}`;
    },

    formatPrice(price) {
      if (typeof price === "number") {
        return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + " €";
      } else return "";
    },

    // Format Currency
    formatCurrency(number, min = 2, max = 2) {
      if (typeof number === "number") {
        return new Intl.NumberFormat("fi-FI", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: min,
          maximumFractionDigits: max,
        }).format(number);
      } else return " - €";
    },

    formatCardPrice(price) {
      if (typeof price === "number") {
        return price.toFixed(0).replace(/\d(?=(\d{3})+\.)/g, "$&,") + " €";
      } else return "";
    },

    formatDate(date) {
      if (date) return moment(date).format("DD.MM.YYYY");
      else return "";
    },

    formatBoolean(boolean) {
      if (typeof boolean === "undefined") {
        return "";
      } else {
        return boolean == false ? "Ei" : "Kyllä";
      }
    },

    downloadCsv(data, name, start, end) {
      var blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      if (start && end) a.setAttribute("download", `${start}-${end}_${name}.csv`);
      else a.setAttribute("download", `${name}.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    downloadFile(buffer, name) {
      let blob = new Blob([buffer]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", name);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    downloadExcel(buffer, name, start, end) {
      let blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      if (start && end) a.setAttribute("download", `${start}-${end}_${name}.xlsx`);
      else a.setAttribute("download", `${name}.xlsx`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
