import accountService from "../../services/account.service";

const state = {
  loading: true,
  settings: { font: "sans-serif" },
  colors: {},
};

const mutations = {
  setAccountSettings(state, settings) {
    state.settings = settings;
  },
};

const actions = {
  getAccountData({ commit }) {
    return accountService
      .getAccountData()
      .then((res) => {
        commit("setAccountSettings", res.data.settings);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
