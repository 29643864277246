import { apiAgent } from "../services/apiAgent";

export default {
  async submitContactForm(form) {
    return apiAgent.post("contact/submit-contact-form/", form);
  },

  async submitEmptyResultForm(form) {
    return apiAgent.post("contact/submit-empty-results-form/", form);
  },
};
