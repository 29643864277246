import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import fi from "vuetify/es5/locale/fi";

Vue.use(Vuetify);

Vue.component("MyComponent", {
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = "fi";
    },
  },
});

export default new Vuetify({
  lang: {
    locales: { fi },
    current: "fi",
  },
});
