import apartmentService from "../../services/apartment.service";
import { showPopup } from "../../utils/helpers";

const searchTerms = localStorage.getItem("searchTerms");

const state = {
  apartments: [],
  totalLength: 1,
  itemsPerPage: 32,
  apartment: { condominium: { buildYear: 2000 }, images: [] },
  page: 1,
  loadingApartments: true,
  newSearchNeeded: false,
  searchMessage: "Etsi vapaita kohteita",
  searchTerms: searchTerms
    ? JSON.parse(searchTerms)
    : {
        location: "",
        condominium: "",
        startDate: "",
        rentTime: null,
        freeText: "",
        areaMin: null,
        areaMax: null,
        estimatedRentMin: null,
        estimatedRentMax: null,
        houseType: [],
        roomCount: [],
      },
};

const mutations = {
  setApartments(state, apartments) {
    state.apartments = apartments;
  },

  setTotalLength(state, length) {
    state.totalLength = length;
  },

  setApartment(state, apartment) {
    state.apartment = apartment;
  },

  setApartmentsLoading(state, boolean) {
    state.loadingApartments = boolean;
  },

  setSearchTerms(state, data) {
    state.searchTerms[data.field] = data.val;
    localStorage.setItem("searchTerms", JSON.stringify(state.searchTerms));
  },

  clearApartments(state) {
    state.apartments = [];
    state.page = 1;
  },

  setSearchMessage(state, message) {
    state.searchMessage = message;
  },

  setPage(state, page) {
    state.page = page;
  },

  setNewSearchNeeded(state, val) {
    state.newSearchNeeded = val;
  },
};

const actions = {
  async searchApartments({ commit, state }, query) {
    try {
      let res = await apartmentService.searchApartments({
        page: state.page,
        itemsPerPage: state.itemsPerPage,
        search: state.searchTerms,
        query,
      });
      commit("setApartments", res.data.apartments);
      commit("setTotalLength", res.data.totalLength);
      if (res.data.totalLength == 0) {
        commit("setSearchMessage", "Kohteita ei löytynyt hakuehdoillasi...");
      }
      commit("setApartmentsLoading", false);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getOneApartment({ commit }, id) {
    try {
      const res = await apartmentService.getOneApartment(id);
      // Set data
      commit("setApartment", res.data.apartment);
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
